
export const REQUEST_PACKAGES = gql`
  query REQUEST_PACKAGES {
    packages {
      packageId
      companyId
      name
      description
      providerPackageId
      stepType
      packageType
    }
  }
`