export const GET_DOCUMENT_TEMPLATE_PACKAGES = gql`
  query GET_DOCUMENT_TEMPLATE_PACKAGES {
    documentTemplatePackages {
      documentTemplatePackageId
      packageName
      documentTemplates {
        documentTemplateId
        documentName
      }
    }
  }
`
export const SIGN_PACKAGE = gql`
  mutation SIGN_PACKAGE(
    $documentTemplatePackageId: String!
    $applicantId: String!
    $applicantEmail: String!
  ) {
    signPackage(
      documentTemplatePackageId: $documentTemplatePackageId
      applicantId: $applicantId
      applicantEmail: $applicantEmail
    )
  }
`
